<template>
  <div class="biji">
    <!-- <div>时间段:{{starttime}}至{{endtime}}</div> -->

    <!-- <div class="mobile-top">
      <div class="sel-time">
        <p class="start-date">{{ starttime }}</p>
      </div>
      <div class="unsel-time">
        <p class="end-date">{{ endtime == "" ? "请选择结束日期" : endtime }}</p>
      </div>
    </div> -->

    <div class="title">
      <div
        class="btn"
        @click="last()"
      >
        <a-icon type="left" />
      </div>
      <div class="text">{{ Year }}年{{ month }}月</div>
      <div class="btn" @click="next()"><a-icon type="right" /></div>
    </div>

    <div class="head">
      <div
        class="days"
        v-for="(item, index) in ['日', '一', '二', '三', '四', '五', '六']"
        :key="index"
      >
        {{ item }}
      </div>
    </div>

    <div class="wrap">
      <div
        class="span"
        v-for="(item, index) in calendarList"
        :key="index"
        @click="click(item.count)"
        :class="
          item == ''
            ? 'kong'
            : (item.count >= starttime && item.count <= endtime) ||
              item.count == starttime
            ? 'active'
            : ''
        "
      >
        {{ item.value }}
      </div>
    </div>

    <!-- <div class="bottombtn">
      <button class="cancle-btn" @click="cancle()">取消</button>
      <button class="sure-btn" @click="firm()">确定</button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "rili",
  data() {
    return {
      nowtime: "", //当前日期的时间戳

      clickitem: 0, //点击的时间戳
      clickcount: 0, //点击次数
      starttime: "", //开始时间 数字   默认选中当天日期
      endtime: "", //结束时间 数字

      Year: new Date().getFullYear(), //日历上的年份
      month: new Date().getMonth() + 1, //日历上的月份
      Day: new Date().getDate(), //日历上的天份

      nowYear: new Date().getFullYear(),
      nowmonth: new Date().getMonth() + 1,
      nowDay: new Date().getDate(),

      calendarList: [],
    };
  },
  watch: {
    starttime: {
      handler: function (e) {
        if(this.endtime != '' && this.starttime != ''){
            let data = {
                starttime:this.starttime,
                endtime:this.endtime
            }
            this.$emit("dateChange", data);
        }
      },
      immediate: true,
      deep: true,
    },
    endtime: {
      handler: function (e) {
        if(this.endtime != '' && this.starttime != ''){
            let data = {
                starttime:this.starttime,
                endtime:this.endtime
            }
            this.$emit("dateChange", data);
        }
      },
      immediate: true,
      deep: true,
    },

  },
  created() {
    this.Draw(this.nowYear, this.nowmonth);

    let time_month = this.nowmonth; //现在的月份
    let time_day = this.nowDay; //现在的天数
    if (this.nowmonth < 10) {
      time_month = 0 + "" + this.nowmonth;
    }
    if (this.nowDay < 10) {
      time_day = 0 + "" + this.nowDay;
    }

    this.nowtime = this.nowYear + "-" + time_month + "-" + time_day;
    this.starttime = this.nowtime;
    this.endtime = this.nowtime;
  },
  computed: {},

  methods: {
    Draw: function (Year, Month) {
      //日期列表
      var calendar = [];

      //用当月第一天在一周中的日期值作为当月离第一天的天数(获取当月第一天是周几)
      for (
        var i = 1, firstDay = new Date(Year, Month - 1, 1).getDay();
        i <= firstDay;
        i++
      ) {
        calendar.push("");
      }

      //用当月最后一天在一个月中的日期值作为当月的天数
      for (
        var i = 1, monthDay = new Date(Year, Month, 0).getDate();
        i <= monthDay;
        i++
      ) {
        let time_month = Month;
        let time_day = i;
        if (Month < 10) {
          time_month = 0 + "" + Month;
        }
        if (i < 10) {
          time_day = 0 + "" + i;
        }

        calendar.push({
          value: i,
          count: Year + "-" + time_month + "-" + time_day,
        });
      }
      this.calendarList = calendar;
      // console.log(calendar)
    },

    last() {
      this.month--;
      if (this.month == 0) {
        this.month = 12;
        this.Year--;
      }

      this.Draw(this.Year, this.month);
    },

    next() {
      this.month++;
      if (this.month == 13) {
        this.month = 1;
        this.Year++;
      }

      this.Draw(this.Year, this.month);
    },

    click(item) {
      this.clickcount++;
      this.clickitem = item;

      //开始日期
      if (this.clickcount % 2 == 1) {
        this.starttime = this.clickitem;
        this.endtime = "";
      } else {
        this.endtime = this.clickitem;
        if (this.starttime > this.endtime) {
          this.endtime = this.starttime;
          this.starttime = this.clickitem;
        }
      }
    },

    firm() {},

    cancle() {
      this.starttime = this.nowtime;
      this.endtime = this.nowtime;
    },
  },
};
</script>

<style lang="scss">
.wrap {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.span {
  width: 40px;
  height: 40px;
  background: #fff;
  color: #999;
  float: left;
  text-align: center;
  line-height: 40px;

  &.active {
    background: #1890ffa3;
    color: #fff;
  }

  &.noclick {
    pointer-events: none;
  }

  &.kong {
    background: #fff;
    pointer-events: none;
  }
}

.mobile-top {
  display: flex;
  flex-wrap: nowrap;
  background: #fff;
  padding: 0.1rem 0;
  .sel-time {
    text-align: center;
    width: 50%;
    // border-bottom: solid 2px #2a81e8;
    .start-date {
      color: #b1b1b1;
    }
  }

  .unsel-time {
    text-align: center;
    width: 50%;
    .end-date {
      color: #b1b1b1;
    }
  }
}

.title {
  width: 100%;
  height: 40px;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  color: #999;
  line-height: 40px;

  .btn {
    width: 1.2rem;
    &.noclick {
      pointer-events: none;
    }
  }
  .text {
    flex: 1;
  }
}

.head {
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  height: 40px;
  line-height: 40px;
  .days {
    flex: 1;
  }
}

.bottombtn {
  height: 40px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  button {
    flex: 1;
  }

  .sure-btn {
    background: #037ef5;

    color: #fff;
  }
}
</style>
