<template>
  <div class="all">
    <a-layout id="components-layout-demo-fixed">
      <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
        <span class="logo">{{userInfo.name_scc}}</span>
        <a-menu
          theme="dark"
          mode="horizontal"
          :default-selected-keys="['dashboard']"
          :style="{ lineHeight: '64px', display: 'inline-block' }"
        >
          <a-menu-item
            style="position: relative"
            v-for="item in path"
            :key="item.name"
            @click="goPage(item.name)"
          >
            <a-icon :type="item.icon" />{{ item.title }}
            <span v-if="item.name == 'msg' && $store.state.messageUnread == true" style="background: #ff0000;display: inline-block;width: 6px;height: 6px;border-radius: 50%;position: absolute;top: 20px"></span>
          </a-menu-item>
        </a-menu>
        <div class="exit" @click="exit">
          <a-icon type="logout" style="margin-right: 8px" />登出
        </div>
      </a-layout-header>
      <a-layout-content :style="{ marginTop: '64px' }">
        <div
          class="bottom"
          :style="{
            background: '#fff',
            height: 'calc(100% - 30px)',
            paddingTop: '10px',
            overflow:'auto'
          }"
        >


          <keep-alive :include='cashViews'>
            <router-view></router-view>
          </keep-alive>
        </div>
      </a-layout-content>
      <a-layout-footer :style="{ textAlign: 'center' }" >
        Copyright © 2020 亿立医疗顾问（深圳）有限公司 技术支持：中企高呈
      </a-layout-footer>
    </a-layout>

  </div>
</template>
<script>
import * as Api from './api';
export default {
  name: "home",
  data() {
    return {
      cashViews: ['dashboard'],
      path: [
        { title: "首页", name: "dashboard", icon: "home" },
        { title: "订单", name: "order", icon: "file-protect" },
        // { title: "财务", name: "finance", icon: "transaction" },
        // { title: "预约", name: "subscribe", icon: "schedule" },
        { title: "系统", name: "sys", icon: "setting" },
        { title: "消息", name: "msg", icon: "message" },
      ],
      userInfo:{}
    };
  },
  mounted() {
    this.getWhetherTheNotificationIsARedDot();
    this.userInfo = JSON.parse(localStorage.getItem("user_info"));
  },
  methods: {
    async getWhetherTheNotificationIsARedDot() {
      const res = await Api.GetWhetherTheNotificationIsARedDot({});
      if (res && res.read == true) {
        this.$store.commit('SET_MESSAGE_UNREAD', true)
      }
    },
    goPage(name) {
      this.$router.push({ name: name });
    },
    async exit(){
      let res = await Api.Exit();
          if (res.code == '0') {
            this.$message.success(res.message);
            localStorage.removeItem('login_code');
            setTimeout(()=>{
              this.$router.push({ path: '/login' });
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
    }
  },
};
</script>
<style>
#components-layout-demo-fixed .logo {
  width: auto;
  height: 31px;
  margin: 0px 24px 16px 0;
  float: left;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
#components-layout-demo-fixed .exit {
  float: right;
  color: #fff;
  letter-spacing: 2px;
  font-size: 16px;
  cursor: pointer;
}

#components-layout-demo-fixed .ant-layout-header,
#components-layout-demo-fixed .ant-menu-dark {
  background-color: #1890ff;
}
.ant-layout-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
  font-size: 16px;
}

.header {
  background-color: #1890ff30;
  border-radius: 8px;
  display: flex;
  margin: 0px 10px;
  height: 50px;
}
.nowTime {
  line-height: 50px;
  width: 300px;
  padding-left: 20px;
  font-size: 16px;
}
.operTime {
  line-height: 50px;
  padding-left: 20px;
  color: #1890ff;
  font-size: 16px;
}
.operTime i {
  margin-right: 5px;
}
.ant-layout-footer {
  padding: 10px !important;
}
</style>
