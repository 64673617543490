import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login'),
    title: '登录',
    meta: {
      title: '登录',
    }
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/views/home'),
    title: '首页',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {keepAlive: true},
        component: () => import('@/views/dashboard/index.vue'),
        title: '控制台'
      },
      {
        path: '/subscribe',
        name: 'subscribe',
        component: () => import('@/views/subscribe/list.vue'),
        title: '预约'
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/order/list.vue'),
        title: '订单'
      },
      {
        path: '/finance',
        name: 'finance',
        component: () => import('@/views/finance'),
        title: '财务'
      },
      {
        path: '/sys',
        name: 'sys',
        component: () => import('@/views/sys'),
        title: '系统'
      },
      {
        path: '/msg',
        name: 'msg',
        component: () => import('@/views/msg'),
        title: '消息'
      },
    ],
    meta: {
      title: '首页',
    },
  },

  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  //
  // {
  //   path: '/newsWeb',
  //   name: 'NewsWeb',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/NewsWeb.vue')
  // },
  // {
  //   path: '/system',
  //   name: 'System',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/System.vue')
  // },
  // {
  //   path: '/finance',
  //   name: 'finance',
  //   component: () => import('../views/Finance.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
