import axios from 'axios';
import { Message } from 'element-ui';
import vm from '../main';
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 200000,
});

/**
 * 状态码判断 具体根据当前后台返回业务来定
 * @param {*请求状态码} status
 * @param {*错误信息} err
 */
const errorHandle = (status, err) => {
  switch (status) {
    case 401:
      Message.error('你还未登录');
      break;
    case 404:
      Message.error('请求路径不存在');
      break;
    case 500:
      Message.error('系统繁忙，请稍后重试');
      break;
    default:
      Message.error('网络错误');
  }
};

service.interceptors.request.use(request => {
  if (request['method'] == 'post') {
    vm.$store.commit('SET_REQUEST_LOADING', true)
  }
  if (!request.headers.login_code) {
    request.headers.login_code = window.login_code ? window.login_code : localStorage.getItem('login_code');
  }
  return Promise.resolve(request);
});
/**
 * 响应拦截
 */
service.interceptors.response.use(
  response => {
    if (response.config.method == 'post') {
      setTimeout(()=>{
        vm.$store.commit('SET_REQUEST_LOADING', false)
      }, 2000)
    }
    if (response.status === 200) {

      if (typeof response.data.code == 'undefined') return Promise.resolve(response.data);
      if (response.data.code == '0') {
        return Promise.resolve(response.data);
      }
      switch (response.data.code) {
        case '1':
          return Promise.resolve(response.data);
        case '-200':
          vm.$router.push({
            path: '/login',
          });
          break;
        case '2':
          Message.error(response.data.message);
          return Promise.reject(response.data);
        default:
          Message.error(response.data.message);
          return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(response.data);
    }
  },
  error => {
    vm.$store.commit('SET_REQUEST_LOADING', false);
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在 2xx 的范围
      errorHandle(response.status, response.data.msg);
      return Promise.reject(response.data);
    } else {
      // 处理断网的情况
      if (!window.navigator.onLine) {
        Message.error('你的网络已断开，请检查网络');
      } else {
        Message.error('请求失败,请检查');
      }
      return Promise.reject(error);
    }
  },
);

export default service;
