import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './element-variables.scss'
Vue.use(ElementUI);
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);

// 多个文件显示组件
import customFileList from '@/components/update-multiple';
Vue.component('update-multiple', customFileList);

// 显示图片
import smallImageList from '@/components/small-image-list'
Vue.component('small-image-list', smallImageList);
// 日历
import myCalendar from '@/components/my-calendar';
Vue.component('my-calendar', myCalendar);

Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vm;
