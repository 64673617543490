import request from '@/common/request';
/**
 * 修改密码
 */
export function ChangePwd(data) {
  return request({
    url: '/clinic/info/changePwd',
    method: 'POST',
    data
  });
}

/**
 * 获取是否自动接单
 */
export function GetGlobalSettings(data) {
  return request({
    url: '/clinic/info/getGlobalSettings',
    method: 'GET',
    params: data
  });
}
/**
 * 设置是否自动接单
 */
export function SetGlobalSettings(data) {
  return request({
    url: '/clinic/info/setGlobalSettings',
    method: 'POST',
    data
  });
}

/**
 * 获取二维码
 */
export function GetQRcode(data) {
  return request({
    url: '/clinic/info/getQRcode',
    method: 'GET',
    params:data
  });
}

/**
 * 获取已配置的手机号
 */
export function GetMobileList(data) {
  return request({
    url: '/clinic/info/mobile/list',
    method: 'GET',
    params:data
  });
}

/**
 * 添加手机号
 */
export function MobileSave(data) {
  return request({
    url: '/clinic/info/mobile/save',
    method: 'POST',
    data
  });
}
/**
 * 编辑手机号
 */
export function MobileUpdate(data) {
  return request({
    url: '/clinic/info/mobile/update',
    method: 'POST',
    data
  });
}

/**
 * 删除手机号
 */
export function MobileDelete(data) {
  return request({
    url: '/clinic/info/mobile/delete',
    method: 'GET',
    params: data
  });
}

/**
 * 退出
 * @author wheat
 * */
export function Exit(data) {
  return request({
    url: '/clinic/loginSave/exit',
    method: 'POST',
    data
  });
}
