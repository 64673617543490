import request from '@/common/request';
/**
 * 登录
 */
export function GetInfo(data) {
  return request({
    url: '/clinic/info/getInfo',
    method: 'GET',
    params: data
  });
}
/**
 * 登出
 */
 export function Exit(data) {
  return request({
    url: '/clinic/loginSave/exit',
    method: 'GET',
    params: data
  });
}


// 消息是否出现红点
export function GetWhetherTheNotificationIsARedDot(data) {
  return request({
    url: '/clinic/index/whetherTheNotificationIsARedDot',
    method: 'POST',
    data
  });
}
