import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    requestLoading: false,
    messageUnread: false
  },
  mutations: {
    SET_REQUEST_LOADING(state, data) {
      state.requestLoading = data;
    },
    SET_MESSAGE_UNREAD(state, data) {
      state.messageUnread = data;
    }
  },
  actions: {
  },
  modules: {

  }
})
