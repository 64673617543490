<template>
<div class="menu-box">
  <div class="txt">
    <div class="txt_txt" @click="changeMenu('home')" :class="menuIndex == 'home' ? 'pitch_on' : ''">控制台</div>
    <div class="txt_txt" @click="changeMenu('order')" :class="menuIndex == 'order' ? 'pitch_on' : ''">订单</div>
    <div class="txt_txt" @click="changeMenu('finance')" :class="menuIndex == 'finance' ? 'pitch_on' : ''">财务</div>
    <div class="txt_txt" @click="changeMenu('subscribe')" :class="menuIndex == 'subscribe' ? 'pitch_on' : ''">预约</div>
    <div class="txt_txt" @click="changeMenu('sys')" :class="menuIndex == 'sys' ? 'pitch_on' : ''">系统</div>
    <div class="txt_txt" @click="changeMenu('msg')" :class="menuIndex == 'msg' ? 'el-icon-chat-line-round' : ''"><span>消息</span></div>
  </div>
  <div class="button">
    <img src="../assets/button.png" alt="" @click="openExit"/>
  </div>
</div>
</template>

<script>
import * as Api from "../views/sys/api";

export default {
  name: "top-menu",
  data() {
    return {
      menuIndex: 'home'
    }
  },
  methods: {
    changeMenu(index) {
      this.menuIndex = index;
      if (index == 'home') {
        this.$router.push({ path: '/' });
      } else if (index == 'order') {
        this.$router.push({ path: '/order' });
      } else if (index == 'finance') {
        this.$router.push({ path: '/finance' });
      } else if (index == 'subscribe') {
        this.$router.push({ path: '/subscribe' });
      } else if (index == 'sys') {
        this.$router.push({ path: '/sys' });
      } else if (index == 'msg') {
        this.$router.push({ path: '/msg' });
      }
    },
    async openExit() {
      this.$confirm('您确定要退出当前系统吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await Api.Exit({});
        if (res && res.code == 0) {
          localStorage.removeItem('login_code');
          this.$message({
            type: 'success',
            message: res.message
          });
          setTimeout(()=>{
            this.$router.push({ path: '/login' });
          }, 1500);

        } else {
          this.$message({
            type: 'info',
            message: res.message
          });
        }

      }).catch(() => {});
    }
  }
}
</script>

<style scoped>
.menu-box {
  display: flex;
}
.menu-box .txt_txt{
  cursor: pointer;
}
</style>
