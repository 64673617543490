<template>
 <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>
<script>
import topMenu from './components/top-menu';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export  default {
  data(){
    return {
      locale: zhCN,
      cashViews: ['dashboard']
    }
  },
  components: {
    topMenu
  }
}
</script>
<style lang="less">
@import "./store/css/CSS-Initialization.css";
@import "./store/css/general.less";
</style>
<style lang="less">

#app {
 height: 100%;
}
.ant-layout{
  height: 100%;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
